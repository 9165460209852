import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from "../components/seo"
import Header from '../components/Header';
import KnowledgeCenter from '../components/KnowledgeCenter';
import Footer from '../components/Footer';
import GoTop from '../components/GoTop';
import GoTopMobile from '../components/GoTopMobile';

import { 
  Wrapper,
  GlossaryCard, 
  GlossaryTitle, 
  GlossaryContent,
  GlossaryGrid,
  GlossaryContentCategory,
  GlossaryItem
} from '../components/GlossaryTemplate/styles';

import GlobalStyles from '../styles/GlobalStyles';

const AllCategory = () => {
  const { knowledges, category } = useStaticQuery(graphql`
    query MyCategoryQuery {
      category: allStrapiCategory {
        edges {
          node {
            title
            featured
            slug
            strapiId
            knowledge {
              title
            }
          }
        }
      }
      knowledges: allStrapiKnowledge(sort: {fields: category, order: ASC}) {
        edges {
          node {
            title
            slug
            class {
              title
            }
            strapiId
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO 
        title="SmartEnvios: Categorias"
      />
      
      <Header />
      <KnowledgeCenter />
      <Wrapper id="doc">
        <GlossaryCard>
          <GlossaryTitle>Veja todas as Categorias</GlossaryTitle>
          
          <GlossaryContent>
            {category.edges.map(category => (
              <GlossaryGrid key={category.node.strapiId}>
                <GlossaryContentCategory>{category.node.title}</GlossaryContentCategory>
                {knowledges.edges
                  .filter(a => {return a.node.class.title === category.node.title})
                  .map(glossary => (
                    <GlossaryItem
                      cover 
                      bg="#fff"
                      key={glossary.node.strapiId}
                      to={`/${category.node.slug}/${glossary.node.slug}`}
                    >
                      {glossary.node.title}
                    </GlossaryItem>
                  ))
                }
              </GlossaryGrid>
            ))}
          </GlossaryContent>
        </GlossaryCard>
      </Wrapper>
      <Footer />
      <GoTopMobile />
      <GoTop />
      <GlobalStyles />
    </>
  );
}

export default AllCategory;